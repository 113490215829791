import { mapGetters, mapState, mapMutations } from 'vuex';

import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchGroups,
  fetchBranchByEI,
  fetchClassroomsByEI,
  fetchSchoolCycleByEI,
  fetchEducationalLevel,
  updateGroup,
  createGroup,
  deleteGroup,
} from '../../helpers/KnGroupsOptions';
import { fetchSyllabus } from '../../../employees/helpers/syllabusOptions';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnDocItem from '../../../shared/components/KnDocItem.vue';
import KnPagination from '../../../shared/components/KnPagination.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnDocItem,
    KnPagination,
  },
  mixins: [paginationMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      group: {
        id: 'NA', // No asignado
        nombre_grupo: null,
        sucursal: null,
        salon_clases: null,
        autor: null,
        nivel_educativo: 0,
        plan_estudios: 0,
      },
      durationCicle: null,
      groups: [],
      classrooms: [],
      sucursals: [],
      schoolCycle: [],
      schoolLevels: [],
      syllabus: [],
      toUpdate: false,
      imageFile: null,
      idImage: null,
      ciclo_escolar: null,
      showInactive: false,
      loading: false,
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.fetchData();
  },
  watch: {
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.fetchData();
      },
    },
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    ...mapGetters(['schoolControlEnabled']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear';
    },
    invalidFields() {
      return this.objectHasNulls(this.group);
    },
  },

  methods: {
    ...mapMutations(['setIsLogin']),
    async fetchData() {
      await this.getPaginatedGroups(1);
      this.group.autor = this.userData.id;
      this.group.institucion_educativa = this.institutionId;
      const resBranchs = await fetchBranchByEI(this.institutionId);
      const resClassrooms = await fetchClassroomsByEI(this.institutionId);
      const resSchoolCycles = await fetchSchoolCycleByEI(this.institutionId);
      const { ok: okSchoolLevel, data: dataSchoolLevel } =
        await fetchEducationalLevel({
          institutionId: this.institutionId,
          systemStatus: true,
        });
      const { ok: okSyllabus, data: dataSyllabus } = await fetchSyllabus({
        institutionId: this.institutionId,
        systemStatus: true,
      });
      this.sucursals = resBranchs.results ? resBranchs.results : [];
      this.classrooms = resClassrooms.results ? resClassrooms.results : [];
      this.schoolCycle = resSchoolCycles.results ? resSchoolCycles.results : [];
      this.schoolLevels = okSchoolLevel ? dataSchoolLevel : [];
      this.syllabus = okSyllabus ? dataSyllabus : [];
    },
    async getPaginatedGroups(page = 1) {
      try {
        this.groups = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchGroups({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const group of data) {
              this.groups.push({ ...group });
            }
          } else {
            console.error('No se pudieron obtener los grupos', message);
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los grupos:', error);
        this.loading = false;
      }
    },

    async save() {
      if (this.toUpdate) {
        if (this.imageFile === undefined || this.imageFile == null) {
          await updateGroup(
            this.group.id,
            this.idImage,
            this.group.nombre_grupo,
            this.group.sucursal,
            this.group.salon_clases,
            this.ciclo_escolar,
            this.durationCicle,
            this.group.institucion_educativa.id,
            this.group.nivel_educativo,
            this.group.plan_estudios
          );
          this.clearInline();
          await this.getPaginatedGroups(1);
        } else {
          await updateGroup(
            this.group.id,
            this.imageFile.file,
            this.group.nombre_grupo,
            this.group.sucursal,
            this.group.salon_clases,
            this.ciclo_escolar,
            this.durationCicle,
            this.group.institucion_educativa.id,
            this.group.nivel_educativo,
            this.group.plan_estudios
          );
        }
        this.clearInline();
        await this.getPaginatedGroups(1);
      } else {
        if (this.imageFile === undefined || this.imageFile == null) {
          this.imageFile = null;
          await createGroup(
            this.imageFile,
            this.group.nombre_grupo,
            this.group.sucursal,
            this.group.salon_clases,
            this.ciclo_escolar,
            this.durationCicle,
            this.group.institucion_educativa,
            this.group.nivel_educativo,
            this.group.plan_estudios
          );
          this.clearInline();
        } else {
          await createGroup(
            this.imageFile.file,
            this.group.nombre_grupo,
            this.group.sucursal,
            this.group.salon_clases,
            this.ciclo_escolar,
            this.durationCicle,
            this.group.institucion_educativa,
            this.group.nivel_educativo,
            this.group.plan_estudios
          );
          this.clearInline();
        }
      }
      this.clearInline();
      await this.getPaginatedGroups(1);
    },
    clearInline() {
      this.group = {
        id: 'NA', // No asignado
        nombre_grupo: null,
        sucursal: null,
        salon_clases: null,
        autor: this.userData.id,
        institucion_educativa: this.institutionId,
        nivel_educativo: 0,
        plan_estudios: 0,
      };
      this.ciclo_escolar = null;
      this.durationCicle = null;
      this.clearImage();
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    clear() {
      this.group = {
        id: 'NA', // No asignado
        nombre_grupo: null,
        sucursal: null,
        salon_clases: null,
        autor: this.userData.id,
        institucion_educativa: this.institutionId,
        nivel_educativo: 0,
        plan_estudios: 0,
      };
      this.ciclo_escolar = null;
      this.durationCicle = null;
      this.clearImage();
      this.toUpdate = false;
      this.$refs.form.resetValidation();
    },
    edit(item) {
      this.group = { ...item };
      this.group.sucursal = item.sucursal.id;
      this.group.salon_clases = item.salon_clases.id;
      this.toUpdate = true;

      if (
        item.ciclo_escolar !== 0 &&
        item.ciclo_escolar !== undefined &&
        item.ciclo_escolar !== null
      ) {
        this.ciclo_escolar = item.ciclo_escolar.id;
      }
      if (
        item.duracion_ciclo_escolar_meses !== null &&
        item.duracion_ciclo_escolar_meses !== undefined &&
        item.duracion_ciclo_escolar_meses !== ''
      ) {
        this.durationCicle = item.duracion_ciclo_escolar_meses;
      }
      if (item.imagen_grupo !== undefined && item.imagen_grupo !== null) {
        this.imageFile = item.imagen_grupo.imagen;
        this.idImage = item.imagen_grupo.id;
      }
      if (item.plan_estudios) {
        this.group.plan_estudios = item.plan_estudios.id;
      }
      if (item.nivel_educativo) {
        this.group.nivel_educativo = item.nivel_educativo.id;
      }
    },
    async deleteInline(itemId) {
      await deleteGroup(itemId);
      await this.getPaginatedGroups(1);
      //this.groups = await fetchGroupsByEI(this.institutionId); //this.fetchResultsByEI('personas', 'grupo', this.institutionId)
      this.clearInline();
    },
    saveImage(obj) {
      this.imageFile = obj;
    },
    clearImage() {
      this.imageFile = null;
    },
  },
};
