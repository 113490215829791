<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel v-if="canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Nivel educativo</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnEducationalLevel />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('mvcicloescolar')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Ciclo escolar</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-group-school-cycle />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Grupos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-group-groups />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="schoolControlEnabled && canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Área propedéutica</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnPropaedeuticArea />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="schoolControlEnabled && canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Campo formativo</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnTrainingField />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="schoolControlEnabled && canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Periodo evaluación</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnEvaluationPeriod />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="schoolControlEnabled && canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Materias</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnSubject />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="schoolControlEnabled && canView('grupo')">
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Actividad extraescolar</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <KnExtracurricularActivity />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import KnGroupGroups from '../components/KnGroupGroups/KnGroupGroups.vue';
import KnSubject from '../components/KnSubject/KnSubject.vue';
import KnEvaluationPeriod from '../components/KnEvaluationPeriod/KnEvaluationPeriod.vue';
import KnPropaedeuticArea from '../components/KnPropaedeuticArea/KnPropaedeuticArea.vue';
import KnTrainingField from '../components/KnTrainingField/KnTrainingField.vue';
import KnExtracurricularActivity from '../components/KnExtracurricularActivity/KnExtracurricularActivity.vue';
import KnGroupSchoolCycle from '../components/KnGroupSchoolCycle/KnGroupSchoolCycle.vue';
import KnEducationalLevel from '../components/KnEducationalLevel/KnEducationalLevel.vue';
import { canView } from '../../shared/helpers/permissionsUtils.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    KnGroupGroups,
    KnEvaluationPeriod,
    KnPropaedeuticArea,
    KnGroupSchoolCycle,
    KnSubject,
    KnTrainingField,
    KnExtracurricularActivity,
    KnEducationalLevel,
  },
  computed: {
    ...mapGetters(['schoolControlEnabled']),
  },
  methods: {
    canView: canView,
  },
};
</script>

<style></style>
