<template>
  <section>
    <v-container>
      <v-row class="pt-15" justify="center" align="center">
        <v-col cols="12" md="8" lg="8" xl="5">
          <v-row justify="center">
            <v-col cols="12">
              <h2 class="text-center">Configuración</h2>
            </v-col>
          </v-row>
          <kn-tabs :items="allowedTabs" @tabValue="setTabValue" />
          <div v-if="valueDeterminate === 11.1">
            <expenses-setting />
          </div>
          <div v-if="valueDeterminate === 22.2">
            <income-setting />
          </div>
          <div v-if="valueDeterminate === 33.3">
            <employee-setting />
          </div>
          <div v-if="valueDeterminate === 44.4">
            <inventory-setting />
          </div>
          <div v-if="valueDeterminate === 55.5">
            <groups-setting />
          </div>
          <div v-if="valueDeterminate === 66.6">
            <financial-variables-setting />
          </div>
          <div v-if="valueDeterminate === 77.7">
            <suppliers-setting />
          </div>
          <div v-if="valueDeterminate === 88.8">
            <products-setting />
          </div>
          <div v-if="valueDeterminate === 100">
            <interface-setting />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import KnTabs from '../../shared/components/KnTabs.vue';
import EmployeeSetting from './EmployeeSetting.vue';
import ExpensesSetting from './ExpensesSetting.vue';
import FinancialVariablesSetting from './FinancialVariablesSetting.vue';
import GroupsSetting from './GroupsSetting.vue';
import IncomeSetting from './IncomeSetting.vue';
import InventorySetting from './InventorySetting.vue';
import ProductsSetting from './ProductsSetting.vue';
import SuppliersSetting from './SuppliersSetting.vue';
import InterfaceSetting from './interfaceSetting/InterfaceSetting.vue';
import { canView } from '../../shared/helpers/permissionsUtils.js';
export default {
  components: {
    ExpensesSetting,
    EmployeeSetting,
    IncomeSetting,
    FinancialVariablesSetting,
    GroupsSetting,
    KnTabs,
    SuppliersSetting,
    ProductsSetting,
    InventorySetting,
    InterfaceSetting,
  },
  data() {
    return {
      valueDeterminate: 11.1,
      tabs: [
        { name: 'Egresos', value: 11.1, resource: 'mvcategoriaegreso' },
        { name: 'Ingresos', value: 22.2, resource: 'mvcategoriaingreso' },
        { name: 'Empleados', value: 33.3, resource: 'mvtipoempleado' },
        { name: 'Inventarios', value: 44.4, resource: 'inventario' },
        { name: 'Control escolar', value: 55.5, resource: 'mvcicloescolar' },
        { name: 'Variables financieras', value: 66.6, resource: 'colegiatura' },
        { name: 'Proveedores', value: 77.7, resource: 'proveedor' },
        { name: 'Productos', value: 88.8, resource: 'mvcategoriaproducto' },
        { name: 'Institución', value: 100, resource: 'tema' },
      ],
    };
  },
  computed: {
    ...mapGetters(['schoolControlEnabled']),
    allowedTabs() {
      return this.tabs.map((tab) => {
        if (!this.schoolControlEnabled && tab.value === 55.5) {
          tab.name = 'Grupos';
        }
        if (canView(tab.resource)) {
          tab.disabled = false;
        } else {
          tab.disabled = true;
        }
        return tab;
      });
    },
  },
  created() {
    this.setIsLogin(false);
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    setTabValue(val) {
      this.valueDeterminate = val;
    },
  },
};
</script>

<style></style>
